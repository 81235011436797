import React from 'react';
import * as he from 'he';
import Container from '../components/container/container';
import Layout from '../components/layout/layout';
import PageHeader from '../components/paragraphs/page-header/page-header';
import SEO from '../components/seo';
import './recipe.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUser } from '@fortawesome/free-regular-svg-icons';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import { Fade } from 'react-reveal';
import { graphql } from 'gatsby';

export default function Recipe(props) {
  const { data, location, pageContext } = props;
  const { langCode, nid } = pageContext;
  const recept = data.nodeRecept;

  return (
    <Layout location={location} lang={langCode} nid={nid}>
      <SEO
        title={he.decode(recept.title)}
        description={
          recept.body && recept.body.summary ? recept.body.summary : null
        }
        lang={langCode}
        nid={nid}
      />
      <PageHeader
        align={'Links'}
        colors={'Groen'}
        title={recept.title}
        breadcrumbs={[
          { url: '/', title: 'Home' },
          // { url: '/inspiratie', title: 'Inspiratie' },
          { url: '/recepten', title: 'Recepten' }
        ]}
        langCode={langCode}
      ></PageHeader>
      <div className="recipe-wrapper">
        <Container>
          <div className="recipe-container">
            <div className="ingredients">
              <h3>{recept.relationships.field_ingredienten_1.field_titel}</h3>
              <h4>{recept.relationships.field_portion.name}</h4>
              <div className="parts">
                {recept.relationships.field_ingredienten_1.relationships.field_ingredienten_groepen.map(
                  group => {
                    return (
                      <div className="part">
                        {group.field_titel && <h5>{group.field_titel}</h5>}
                        <div
                          className="group-ingredients"
                          dangerouslySetInnerHTML={{
                            __html: group.field_ingredienten_1.processed
                          }}
                        ></div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="recipe">
              <Fade>
                <div className="images">
                  <img
                    src={
                      recept.relationships.field_afbeeldingen[0]
                        .field_media_image.imageDerivatives.links.blog_overview
                        .href
                    }
                    alt={recept.title}
                  />
                  <div
                    className={[
                      'secondary-image',
                      recept.relationships.field_afbeeldingen.length !== 2
                        ? 'fallback'
                        : ''
                    ].join(' ')}
                  >
                    {recept.relationships.field_afbeeldingen.length === 2 && (
                      <img
                        src={
                          recept.relationships.field_afbeeldingen[1]
                            .field_media_image.imageDerivatives.links
                            .blog_overview.href
                        }
                        alt={recept.title}
                      />
                    )}
                    <div className="info">
                      <p>
                        <FontAwesomeIcon icon={faClock} />{' '}
                        {recept.relationships.field_duration.name}
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faUser} />{' '}
                        {recept.relationships.field_portion.name}
                      </p>
                      <p>
                        {recept.relationships.field_recipe_tags.length > 0 && (
                          <>
                            <FontAwesomeIcon icon={faTags} />{' '}
                            {recept.relationships.field_recipe_tags[0]?.name}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
              <div className="steps">
                <h3>Zo maak je het</h3>
                {recept.relationships.field_recipe_steps.map((step, i) => {
                  return (
                    <div
                      key={i}
                      className={`step ${
                        step.relationships.field_media ? 'with-image' : ''
                      }`}
                    >
                      <div className="bubble">{i + 1}</div>
                      <div className="content">
                        {step.relationships.field_media && (
                          <img
                            src={
                              step.relationships.field_media.field_media_image
                                .imageDerivatives.links.story_paragraph.href
                            }
                            alt=""
                          />
                        )}
                        <p>{step.field_recipe_step_description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    allNodeCustomer(sort: { fields: [created], order: DESC }) {
      nodes {
        created
        title
        field_quote
        field_location
        field_website {
          uri
        }
        field_logo {
          imageDerivatives {
            links {
              icon_landscape {
                href
              }
            }
          }
        }
      }
    }

    nodeRecept(path: { alias: { eq: $slug } }, status: { eq: true }) {
      id
      title
      path {
        alias
      }
      relationships {
        field_ingredienten_1 {
          relationships {
            field_ingredienten_groepen {
              field_ingredienten_1 {
                processed
              }
              field_titel
            }
          }
          field_titel
        }
        field_portion {
          name
        }
        field_afbeeldingen {
          field_media_image {
            imageDerivatives {
              links {
                blog_overview {
                  href
                }
              }
            }
          }
        }
        field_duration {
          name
          id
        }
        field_portion {
          name
          id
        }
        field_recipe_steps {
          relationships {
            field_media {
              field_media_image {
                imageDerivatives {
                  links {
                    story_paragraph {
                      href
                    }
                  }
                }
              }
            }
          }
          field_recipe_step_description
        }
        field_recipe_tags {
          name
          id
        }
        field_ingredienten {
          field_title {
            processed
          }
          relationships {
            field_g_portion {
              name
            }
            field_ingredienten {
              field_hoeveelheid
              relationships {
                field_ingredient {
                  title
                  id
                  field_photo {
                    imageDerivatives {
                      links {
                        cursor {
                          href
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
